import { FormLayout, TextField, Button } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSetting, saveSetting } from "../services/setting.service";
import { getErrorMessage } from "../utils/helpers";

function SettingsPage() {
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          shop: window?.SERVER_DATA?.shop,
        };
        const response = await getSetting(params);
        if(response?.success){
          setOpenTime(response?.settings?.openTime || "");
          setCloseTime(response?.settings?.closeTime || "");
        }
        console.log("response: ", response);
      } catch (error) {
        const errorMsg = getErrorMessage(error);
        toast.error(errorMsg);
      }
    };
    fetchData();
  }
  , []);

  const handleOpenTimeChange = (value) => {
    setOpenTime(value);
    console.log("start: ", value);
  };

  const handleCloseTimeChange = (value) => {
    setCloseTime(value);
    console.log("end: ", value);
  };

  const saveStoreTiming = async () => {
    if (openTime === "" || closeTime === "")
      return toast.error("Please fill all the fields");
    if (openTime >= closeTime)
      return toast.error("Open time should be less than close time");

    const data = {
      openTime,
      closeTime,
      shop: window?.SERVER_DATA?.shop,
    };
    try {
      const response = await saveSetting(data);
      console.log("response11: ", response);
      if(response?.success){
        toast.success(response?.message || "Store timing saved successfully");
      }
      console.log("response22: ", response);
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      toast.error(errorMsg);
    }

    console.log("Store timing saved");
  };

  return (
    <FormLayout>
      <TextField
        value={openTime}
        label="Open Time"
        type="time"
        onChange={handleOpenTimeChange}
        autoComplete="off"
      />
      <TextField
        value={closeTime}
        label="Close Time"
        type="time"
        onChange={handleCloseTimeChange}
        autoComplete="off"
      />
      <Button onClick={saveStoreTiming} primary>
        Submit
      </Button>
    </FormLayout>
  );
}

export default SettingsPage;
