import logo from './logo.svg';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@shopify/polaris/build/esm/styles.css';
import {AppProvider} from '@shopify/polaris';
import SettingsPage from './components/SettingsPage';

function App() {
  return (
    <AppProvider>

    <div className="App">
      <ToastContainer />
      <SettingsPage />
    </div>
    </AppProvider>
  );
}

export default App;
