import { apiUrl } from "../utils/constants";
import { GET, POST } from "./api.service.wrapper";

export const saveSetting = async (setting) => {
  const response = await POST(`${apiUrl.saveSettings}`, setting);
  return response;
};

export const getSetting = async (params) => {
  const response = await GET(`${apiUrl.getSettings}`, params);
  return response;
}
